const services = [
  {
    title:
      "الخدمات الاستشارية في مجال تقنية المعلومات، الاتصالات وأمن المعلومات",
    desc:
      "بخبرات تراكمية تفوق الثلاثين عاما لعناصر شركة التميز وشركائها في مجال الاتصالات وتقنية المعلومات ستوفر الشركة الخدمات الاستشارية لكل الجهات الراغبة بما سينعكس إيجابا على توفير الجهد، الوقت والميزانيات لصالح الجهات المستفيدة. وفي سبيل ذلك ستضمن لكم شركة التميز قدرات فريقها الاستشاري المهنية والإدارية والتواصل مع كافة الأطراف في الخدمات التي تخص المشاريع وقدرتها على توفير النصح بخصوص افضل الممارسات الدولية لانجازها.",
  },
  {
    title:
      "إعداد وتقديم خدمات الأبحاث الأمنية بما في ذلك الأمن المادي للقطاع المدني والعسكري",
    desc:
      "إن قدرة شركة التميز لتزويدكم بآخر المستجدات على الساحة الدولية العلمية فيما يخص أمن الاتصالات وتقنية المعلومات نابع من طبيعة عملها وعدم غرقها في الأعمال اليومية التشغيلية التي تعلق بها الإدارات والوحدات التابعة للمؤسسات العاملة في المجالات المختلفة والتي ترتبط بتعهدات تجاه زبائن خدماتها وفي نفس الوقت هذا ما يميز شركتنا من حيث أن هذه هي طبيعة عملنا والتي تتضمن تقديم الخدمات الاستشارية لطيف واسع ومختلف من الزبائن يحتم علينا اللحاق بكل جديد.",
  },
  {
    title:
      "إعداد ومراجعة المواصفات الفنية المتعلقة بتقنية المعلومات، الاتصالات وأمن المعلومات",
    desc:
      "ستعمل شركة التميز ومن خلال خبرة موظفيها وشركائها على تزويدكم بالخدمات الاستشارية والوثائق الدقيقة لطلبات العروض والعروض الفنية، معتمدة في ذلك على خبرة موظفيها العالية في مجال دراسة وتقييم العروض الفنية وإعدادها التي تفوق 20 سنة خبرة تراكمية.",
  },
  {
    title: "تنفيذ نظام إدارة أمن المعلومات آيزو 27001:2013",
    desc:
      "ستعمل شركة التميز للاستشارات وأمن المعلومات على تقديم المساعدة للمؤسسات الليبية فيما يخص تنفيذ نظام إدارة أمن المعلومات من خلال مهندسيها لتغطية جميع المتطلبات الضرورية وتنفيذ ضوابط الحماية حسب ما سيتم سرده ضمن بيان التطابق بحيث يتم وضع أداوات الحماية في مكانها المناسب وضمن أفضل ميزانية وتوقيت.",
  },
  {
    title: "تنفيذ نظام استمرارية الأعمال آيزو 22301:2012",
    desc:
      "ستعمل شركة التميز للاستشارات وأمن المعلومات مع الشريك الأساسي في الأعمال شركة زيرشانس على تقديم المساعدة للمؤسسات الليبية فيما يخص تنفيذ نظام إدارة استمرارية الأعمال بتعاون مع مهندسيها لتغطية جميع المتطلبات الضرورية وتنفيذ ضوابط الحماية ووضع أدوات الحماية في مكانها المناسب وضمن أفضل ميزانية وتوقيت.",
  },
  {
    title: "تنفيذ متطلبات نظام حماية بطاقات الدفع الائتمانية PCIDSS",
    desc:
      "ستعمل شركة التميز للاستشارات وأمن المعلومات مع شريكها الأساسي شركة داتا بروتيكت والمعتمدة دوليا في تنفيذ مشاريع الالتزام بمتطلبات المعيار الدولي لحماية معلومات حاملي البطاقات الائتمانية من خلال تقديم المساعدة للمؤسسات الليبية في كل يخص تنفيذ الالتزام بمتطلبات المعيار، التدقيق الأولي، إدارة المخاطر، جمع الأدلة واختبار نقاط الضعف ومن تم المعالجات ووضع أدوات الحماية في مكانها المناسب وضمن أفضل ميزانية وتوقيت.",
  },
  {
    title: "فحص نقاط الضعف والتدقيق على أمن المعلومات",
    desc:
      "فريق من الخبراء في فحص وتقييم نقاط الضعف والتدقيق على أمن المعلومات ليشمل من تقييم بنية إدارات ووحدات أمن المعلومات، سير الأعمال ودقتها، تقييم خبرات الموظفين وكفاءاتهم، تقييم الأمن الإداري والمادي، تقييم العمليات كل ذلك استنادا لأفضل الممارسات الدولية لحماية الأهداف الحيوية.",
  },
  {
    title: "الاستشارات الخاصة بنظم التشفير والحماية",
    desc:
      "آمر مكتب تقنية المعلومات والتشفير بالقوات البحرية هو المنصب الأخير لمؤسس شركة التميز وهو حصيلة مشروع لنقل المعرفة في مجال صناعة لوغريثمات التشفير المشترك بين الجيش الليبي وشركة انتراكوم الدفاعية اليونانية وكواحد من عشرة مبتعثين لنقل المعرفة في المجال استطاع مع رفاقه تصميم وصناعة أول لوغريثم تشفير ليبي ومن ذلك الوقت عمل مع رفاقه على طيف مختلف من المعدات العسكرية للتشفير وحماية المعلومات من خلال الاتصالات السلكية واللاسلكية معززا بذلك الاعتراف الممنوح له بدرجة الماجستير في مجال الاتصالات وتقنية المعلومات تخصص أمن معلومات. ولذلك فإن الخبرة التي سيتم تقديمها لكم مبنية على المعرفة الدقيقة في مجال التشفير والشبكة الواسعة من الشركات الدولية التي نمتلكها .",
  },
  {
    title: "تقديم برامج التوعية الأمنية",
    desc:
      "الخط الدفاعي الأول هو التدريب والتوعية ومن أجل ذلك كانت مبادرة التوعية الأمنية آمن+ هي أول ما قمنا بانجازه منذ العام 2013 والتي كانت ولا تزال متاحة كمادة تدريبية بشكل مجاني للجهات وللاغراض الغير ربحية. كما يمكننا تصميم وتقديم دورات التوعية الأمنية لجميع الجهات الراغبة في ذلك وبأعلى مستوى من ناحية التقديم والمحتوى التدريبي.خبرة مدربين المعتمدين في المجال والتدريس بالجامعات والمعاهد الليبية هي أيضا ركيزة مهمة نعتمد عليها في طرحنا.",
  },
  {
    title: "خدمات تقييم نقاط الضعف واختبار الاختراقات",
    desc:
      "خدمتي تقييم نقاط الضعف واختبار الاختراقات من الخدمات التي يمكن لشركة التميز تقديمها بالسوق الليبي ولكافة الجهات بالقطاعين المدني والعسكري، يكمن الفرق الأساسي بين خدمتي تقييم نقاط الضعف واختبار الاختراقات في أن الخدمة الأولى ستقتصر على فحص الأنظمة المستهدفة وتقديم تقرير بخصوص نقاط الضعف بناء على مؤشرات خاصة تستخرج من الأنظمة والمعدات محل التقييم، فيما أن عملية اختبار الاختراقات تتضمن عمليات استغلال نقاط الضعف وتنفيذ التهديد وملاحظة الأثر تحت سيطرة المختبر الأخلاقي وفي نطاق محدد وبموافقة طالب الخدمة.",
  },
  {
    title:
      "الخدمات الاستشارية في نظم الاتصالات اللاسلكية العسكرية (المشفرة والمفتوحة)",
    desc:
      "فريق كامل من المستشارين الوطنيين في مجال الاتصالات العسكرية ونظم الحماية سيكون متوفر لتقديم خدماته للجهات العسكرية والأمنية داخل ليبيا، سيتم العمل على تلبية متطلبات الجهات الحساسة من خلال تقديم الخدمات الاستشارية وربط تلك الجهات بكبار المصنعين مباشرة دون أي وسطاء للمحافظة على أسرار الدولة الليبية، المحافظة على المال العام من خلال تقليل الأسعار لتقارب سعر المصنع مباشرة بالاضافة فقط لقيمة الاستشارات الفنية وضمان أفضل شروط تزويد بما في ذلك شروط التدريب، الصيانات وتوفير قطع الغيار.",
  },
]

export default services
