import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React from "react"
import Layout from "../components/Layout"
import CoreValues from "../components/CoreValues"
import servicesArray from "../components/constants/Services"
import { ExpandMoreSharp } from "@material-ui/icons"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  sectionTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  service: {
    marginBottom: ".1rem",
  },
  serviceTitle: {
    fontSize: "2.4rem",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  serviceDesc: {
    textAlign: "right",
    fontSize: "1.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  littleRedSquare: {
    backgroundColor: "rgba(195, 0, 0, 1)",
    width: "1.5rem",
    height: "1.5rem",
    display: "inline-block",
    marginLeft: "1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      width: "1rem",
      height: "1rem",
    },
  },
  button: {
    fontSize: "1.8rem",
    marginTop: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
}))

const Services = () => {
  const pageMeta = {
    title: "الخدمات الأمنية",
    description: "خدمات أمنية",
    keywords: [
      "أمن المعلومات",
      "حماية أمنية",
      "استشارات أمنية",
      "خدمات أمنية",
      "شهادات أمنية معتمدة",
      "توعية أمنية",
      "تشفير",
      "ضعف امني",
      "بطاقات الكترونية",
      "ادارة أمنية",
      "امن عسكري",
      "ابحاث امنية",
      "آيزو",
      "iso",
      "pcidss",
    ],
  }
  const classes = useStyles()
  const matches600 = useMediaQuery("(max-width: 600px)")

  return (
    <Layout pageMeta={pageMeta} isHero>
      <Grid container direction="column">
        <Grid item container className={classes.sectionSeperator}>
          <CoreValues />
        </Grid>

        {/* Services */}
        <Grid item align="center">
          <Typography className={classes.sectionTitle} paragraph>
            الخدمات والأبحاث الأمنية التي نقدمها لحماية مؤسستك
          </Typography>
        </Grid>
        <Grid item container direction="column">
          {servicesArray.map((service, index) => {
            return (
              <Grid
                item
                className={classes.service}
                key={`${service} ${index}`}
              >
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  style={{ padding: "1rem" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreSharp
                        fontSize="large"
                        fontWeight="bold"
                        color="primary"
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.serviceTitle}>
                      <span className={classes.littleRedSquare} />
                      {servicesArray[index].title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.serviceDesc}>
                      {servicesArray[index].desc}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })}
          <Grid item className={classes.sectionSeperatorShadowed} />
        </Grid>

        {/* ISO */}
        <Grid item container direction="column">
          <Grid item container direction="column">
            <Grid item>
              <Typography
                className={classes.serviceTitle}
                paragraph
                align={matches600 ? "center" : "right"}
              >
                معيار نظام إدارة أمن المعلومات - آيزو 27001
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.serviceDesc}
                align={matches600 ? "center" : "right"}
                paragraph
              >
                يركز المعيار آيزو 27001 على حماية معلومات المؤسسة على مستوى
                الخصوصية، السلامة والقدرة على الوصول. ينجز هذا العمل من خلال
                إيجاد المشاكل المحتملة التي يمكن أن تحدث للمعلومات (مايسمى تقييم
                الخطر)، ومن ثم تحديد الاحتياجات المطلوبة لمنع مثل هذه المشاكل من
                الحدوث (مايسمى تقليل المخاطر أو معالجة المخاطر). وبناء عليه فإن
                فلسفة المعيار آيزو 27001 تقوم على أساس إدارة المخاطر: إيجاد
                مكامن الأخطار، ومن ثم معالجتها بطريقة منهجية.
                <br />
                ستعمل شركة التميز للاستشارات وأمن المعلومات على تقديم المساعدة
                للمؤسسات الليبية فيما يخص تطبيق نظام إدارة أمن المعلومات من خلال
                مهندسيها لتغطية جميع المتطلبات الضرورية وتنفيذ ضوابط الحماية حسب
                ما سيتم سرده ضمن بيان التطابق بحيث يتم وضع أدوات الحماية في
                مكانها المناسب وضمن أفضل ميزانية وتوقيت.
              </Typography>
            </Grid>
            <Grid item container justify={matches600 ? "center" : "flex-start"}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/iso_services"
                className={classes.button}
              >
                لمعرفة المزيد
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.sectionSeperatorShadowed} />
        </Grid>

        {/* PCIDSS */}
        <Grid item container direction="column">
          <Grid item container direction="column">
            <Grid item>
              <Typography
                className={classes.serviceTitle}
                paragraph
                align={matches600 ? "center" : "right"}
              >
                معيار أمن بيانات صناعة بطاقات الدفع الائتمانية PCI DSS
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.serviceDesc}
                align={matches600 ? "center" : "right"}
                paragraph
              >
                معيار أمن بيانات صناعة بطاقة الدفع الإئتمانية (PCI DSS) هو معيار
                صمم لدعم وتعزيز حماية بيانات حاملي البطاقات الإئتمانية وضمان
                الالتزام الدولي بمقياس موحد لتقييم ذلك.
                <br />
                يعمل الالتزام بالمعيار على تزويد المؤسسات المالية، مقدمي الخدمات
                وجميع الأطراف المشتركة في التعامل مع بيانات حامل البطاقة بداية
                من نقاط البيع، التاجر، العملاء الماليين، المصدرين، مقدمي الخدمة
                والمصرف المشتري بالمبادئ الاساسية للمتطلبات التشغيلية والتقنية
                المصممة لحماية بيانات الحسابات الخاصة بحاملي البطاقات.
                <br />
                كما ينطبق المعيار على جميع الهيئات الأخرى التي تعمل على تخزين،
                معالجة أو نقل بيانات حاملي البطاقات (CHD) و/أو بينات المصادقة
                الحساسة (SAD).
              </Typography>
            </Grid>
            <Grid item container justify={matches600 ? "center" : "flex-start"}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/pcidss_services"
                className={classes.button}
              >
                لمعرفة المزيد
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.sectionSeperatorShadowed} />
        </Grid>

        {/* Call to action */}
        <Grid
          item
          container
          direction="column"
          justify="center"
          align="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              className={classes.sectionTitle}
              style={{ marginBottom: "2rem" }}
            >
              كيف يمكننا تأمينك؟
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.serviceDesc} paragraph>
              نعمل على تأمين بياناتك بمجموعة من الاجراءات والعمليات الاستباقية
              لفهم المخاطر والتهديدات وتوفير التدريب والمعرفة اللازمة وتجهيز فرق
              قادرة على مراقبة واصطياد التهديدات بخبرة واسعة تفوق 25 عاما وتجارب
              متعددة في التعامل مع التهديدات الأمنية
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/contact"
              className={classes.button}
            >
              تواصل معنا
            </Button>
          </Grid>
          <Grid item className={classes.sectionSeperator} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Services
